import client from "./client";

const getUsers = (filter) => {
    return client
        .get(`/users`, filter)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};


const userAPI = {
    getUsers,
};

export default userAPI;


